import React from 'react';
import { FAQ } from 'src/templates';
import {Box, Container, Typography} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@mui/styles';
import { Link } from "gatsby"
import iconoGestionPagos from "src/assets/svg/ayuda/icono-gestion-pago.svg"

export default function GestionDePago() {
 const theme = useTheme();
 const sm = useMediaQuery(theme.breakpoints.down('md'));
 const preguntas = [
  {
   titulo: "Trabajo en una gran empresa y quiero aliarme a Cumplo para pagarle a mis proveedores. ¿Cómo nos asociamos?",
   contenidos: [
    {
     texto: "Conversemos. Llámanos al número ",
     tipo: "TEXTO",
    },
    {
     texto: "+51 914451815",
     tipo: "TEXTO",
     tel: "+51914451815",
    },
    {
     texto: " o escríbenos al correo electrónico ",
     tipo: "TEXTO",
    },
    {
     texto: "cumploperu@cumplo.com",
     tipo: "TEXTO",
     mailto: "cumploperu@cumplo.com",
    },
    {
     texto: " para que hagas las gestiones junto al área comercial.",
     tipo: "TEXTO",
     finParrafo: true,
    },
   ],
  },
  {
   titulo: "¿Puedo hacer que mi cliente gran empresa se asocie a Cumplo para que anticipe el pago de mis facturas?",
   contenidos: [
    {
     texto: "Desde Cumplo podemos hacer las gestiones para que grandes empresas se asocien a nosotros. Llámanos al ",
     tipo: "TEXTO",
    },
    {
     texto: "+51 914451815",
     tipo: "TEXTO",
     tel: "+51914451815",
    },
    {
     texto: " o escríbenos a ",
     tipo: "TEXTO",
    },
    {
     texto: "cumploperu@cumplo.com",
     tipo: "TEXTO",
     mailto: "cumploperu@cumplo.com",
    },
    {
     texto: " para evaluar la posibilidad.",
     tipo: "TEXTO",
     finParrafo: true,
    },
   ],
  },
 ]

 const Cabecera = () => {
  return <Box sx={{
   display: "flex",
   flexDirection: "row",
   alignItems: "flex-end",
   justifyContent: "center",
   color: 'text.secondary',
   minHeight: sm ? '92px' : '99px',
   height: '100%',
   padding: "36px 23px"
  }}/>
 };

 return (
  <Box sx={{ background: `${theme.palette.primary.main}20` }}>
   <Cabecera/>
   <Container>
    <Box sx={{ maxWidth: "1100px", margin: "0 auto"}}>
     <Box sx={{ padding: "72px 0"}}>
      <Box sx={{ marginBottom: "32px"}}>
       <Typography variant="h3" color="primary.dark" fontWeight={500}>Preguntas frecuentes</Typography>
      </Box>
      <Box sx={{ mt: 8, mb: 6}}>
       <Link to="/ayuda/" style={{ textDecoration: "none" }}>
        <Typography display="inline" color="secondary.main" sx={{fontWeight: 700}}>Preguntas frecuentes</Typography>
       </Link>
       <Typography display="inline" sx={{ ml: 2, mr: 2}}>{`>`}</Typography>
       <Typography display="inline">Gestión de pago</Typography>
      </Box>

      <FAQ 
       titulo='Gestión de pago'
       descripcion="Información de soluciones financieras para adquirentes."
       icono={iconoGestionPagos}
       preguntas={preguntas}
      />

     </Box>
    </Box>
   </Container>
  </Box>
 );
}